import React from 'react'
import styled from 'styled-components'

import SEO from '@/components/seo'
import CalculatorStructure from '@/components/CalculatorStructure'
import { LetterToGrade } from '@/components/Calculator/Grade'

import { CALCULATORS } from '@/helpers/mocks/calculators'
import SectionInfoCard from './SectionInfoCard'

const StyledLetterToGradeCalculator = styled(LetterToGrade)`
  min-width: unset;
  max-width: unset;
`

const LetterToGradeCalculator = () => (
  <>
    <SEO title="Letter to Grade" />
    <CalculatorStructure
      section="grade"
      headerTitle="grade"
      title={CALCULATORS.grade['letter-to-grade'].title}
      description={CALCULATORS.grade['letter-to-grade'].description}
      calculatorNode={<StyledLetterToGradeCalculator />}
      asideCards={<SectionInfoCard />}
    />
  </>
)

export default LetterToGradeCalculator
